import { useAppContext } from "../../context";

const AboutSection = () => {
  const { handleScrollToCTA } = useAppContext();

  return (
    <section className="container-section overflow-x-hidden grid grid-cols-1 lg:grid-cols-5 md:grid-cols-1 px-6 sm:px-9 xl:px-[160px] lg:px-[80px] flex-col md:flex-row gap-x-16 gap-y-8 justify-center items-center">
      <div className="relative lg:col-span-3 col-span-1">
        <h2
          data-aos="fade-up"
          className="about-title text-xl lg:text-[40px] color-white leading-[120%] font-bold"
        >
          About Veridian
        </h2>
        <p data-aos="fade-up" className="color-white typography-p mt-4 mb-10">
          Built by Hospitality and Data Management experts, Veridian offers
          bespoke software solutions to help organizations to simplify, automate
          and expedite your data-driven operations.
        </p>
        <button
          onClick={handleScrollToCTA}
          data-aos="fade-up"
          className="button-primary w-fit font-normal mt-8 lg:mt-10 mb-10 lg:mb-20 opacity-80"
        >
          <span>Contact Us</span>
        </button>
      </div>
      <div data-aos="fade-left" className="relative lg:col-span-2 col-span-1">
        <img
          className="mx-auto"
          src="/images/about-veridian-img.webp"
          alt="security-illustration"
        />
      </div>
    </section>
  );
};

export default AboutSection;
