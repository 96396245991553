import { ReactSVG } from "react-svg";

const features = [
  { icon: "reliable-data", title: "Reliable data, anytime" },
  { icon: "one-dashboard", title: "One dashboard, multiple sources" },
  { icon: "integrated", title: "Fully integrated with all key softwares" },
  { icon: "solutions", title: "Bespoke solutions" },
];

const KeyFeaturesSection = () => {
  return (
    <div className="container-section grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-x-10 gap-y-24 px-6 sm:px-9 2xl:px-[160px] xl:px-[80px]">
      {features.map((item, i) => (
        <div
          data-aos="fade-up"
          data-aos-delay={(i + 1) * 100}
          className="grid relative"
          key={item.icon}
        >
          <div className="key-feature-icon absolute">
            <ReactSVG
              className="lg:w-12 lg:h-12 w-8 h-8"
              src={`/icons/icon-48px-${item.icon}.svg`}
            />
          </div>
          <div className="key-feature-box">
            <h3 className="lg:text-2xl font-bold text-center text-white">
              {item.title}
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default KeyFeaturesSection;
