import { ReactSVG } from "react-svg";
import { useAppContext } from "../../context";

const HeroSection = () => {
  const { handleScrollToCTA } = useAppContext();

  return (
    <div className="hero-section">
      <section className="relative flex justify-center flex-col px-6 sm:px-9 lg:px-[120px] hero-section-accent h-[calc(100vh-126px)]">
        <h1
          data-aos="fade-up"
          className="color-white text-3xl lg:text-[72px] leading-[120%] font-bold"
        >
          <span
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            Streamline
          </span>{" "}
          <span
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            Your
          </span>{" "}
          <br />
          <span
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            Hospitality
          </span>{" "}
          <span
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Data
          </span>{" "}
          <br />
          <span
            className="hero-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            with Veridian
          </span>{" "}
        </h1>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="700"
          className="max-w-[1080px] font-normal mt-6 color-white opacity-80 leading-[150%] text-sm lg:text-2xl"
        >
          Software solutions for data management and reconciliation
        </p>

        <button
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="900"
          onClick={handleScrollToCTA}
          className="button-primary w-fit block lg:hidden font-normal mt-8 lg:mt-10 mb-10 lg:mb-20 opacity-80"
        >
          <span>Talk to us</span>
          <ReactSVG src="/icons/arrow_right.svg" className="w-5 h-5" />
        </button>
      </section>
    </div>
  );
};

export default HeroSection;
