import { FormEvent, useState } from "react";
import { ReactSVG } from "react-svg";
import emailjs from "@emailjs/browser";
import { useAppContext } from "../../context";

const CtaFormSection = () => {
  const { ctaSection } = useAppContext();
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY ?? "";
  emailjs.init(publicKey);
  const [result, setResult] = useState<"success" | "error" | "loading">();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!name || !message || !email) {
      setResult("error");
      return;
    }
    setResult("loading");
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID ?? "";
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID ?? "";
    emailjs
      .send(
        serviceId,
        templateId,
        {
          from_name: name,
          message: message,
          from_mail: email,
        },
        publicKey
      )
      .then(() => {
        setResult("success");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch(() => {
        setResult("error");
      })
      .finally(() =>
        setTimeout(() => {
          setResult(undefined);
        }, 3000)
      );
  };

  return (
    <section
      ref={ctaSection}
      className="container-section footer-accent-bg overflow-x-hidden"
    >
      <div className="px-6 sm:px-9 xl:px-[160px] lg:px-[80px] flex flex-col md:flex-row gap-16">
        <div
          className="relative flex-1 flex flex-col gap-y-4 mb-10"
          data-aos="fade-up"
        >
          <h2 className="text-xl hero-title lg:text-[40px] color-white leading-[120%] font-bold">
            Get in touch today
          </h2>
          <p className="color-white typography-p">
            Have a question or need assistance? We're just a message away. Fill
            out the form, and we'll get back to you as soon as possible.
          </p>
          <div className="flex space-x-2 items-center">
            <ReactSVG className="w-5 h-5" src="/icons/message-icon.svg" />
            <p className="color-white typography-p">hello@veridianlab.com</p>
          </div>
          <div className="flex space-x-2 items-center">
            <ReactSVG className="w-5 h-5" src="/icons/location-icon.svg" />
            <p className="color-white typography-p">Singapore</p>
          </div>
        </div>
        <form
          className="relative flex-1 grid gap-6 w-full"
          onSubmit={handleSubmitForm}
          data-aos="fade-left"
        >
          <input
            name="name"
            className="form-input container typography-p focus:border-[#3a9290] focus:border-2"
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            name="email"
            className="form-input container typography-p focus:border-[#3a9290] focus:border-2"
            placeholder="Company Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            cols={6}
            name="company"
            className="form-input-textarea container typography-p h-[124px] focus:border-[#3a9290] focus:border-2"
            placeholder="Please type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            type="submit"
            disabled={result === "loading"}
            className="button-primary block container"
          >
            Send Message
          </button>
          {result === "success" && (
            <div className="flex gap-2 items-center">
              <ReactSVG
                className="w-5 h-5 cta-icon-result"
                src="/icons/check_success_icon.svg"
              />
              <p className="typography-p">
                Thank you! Your inquiry has been sent.
              </p>
            </div>
          )}
          {result === "error" && (
            <div className="flex gap-2 items-center">
              <ReactSVG
                className="w-5 h-5 cta-icon-result"
                src="/icons/info_icon.svg"
              />
              <p className="typography-p">
                It seems there's an issue - please review the form.
              </p>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

export default CtaFormSection;
