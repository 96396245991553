import Aos from "aos";
import { createContext, ReactNode, useContext, useEffect, useRef } from "react";

const AppContext = createContext<{
  ctaSection: React.RefObject<HTMLDivElement>;
  handleScrollToCTA: () => void;
}>({
  ctaSection: { current: null },
  handleScrollToCTA: () => null,
});

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("Must be inside provider");
  }
  return context;
};

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const ctaSection = useRef<HTMLDivElement>(null);

  useEffect(() => {
    Aos.init({});
  }, []);

  const handleScrollToCTA = () => {
    ctaSection.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <AppContext.Provider
      value={{
        ctaSection,
        handleScrollToCTA,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
