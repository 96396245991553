import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

const items = [
  {
    title: "Property Management System (PMS)",
    brands: [
      "oracle-hospitality",
      "rhapsody",
      "protel",
      "shiji",
      "planet",
      "idb",
      "hotelogix",
      "cloudbeds",
      "mews",
      "visual-hotel-program",
    ],
  },
  {
    title: "Online Travel Agency (OTA)",
    brands: [
      "expedia",
      "agoda",
      "opengds",
      "trip",
      "booking",
      "hotels",
      "klook",
      "traveloka",
      "ctrip",
      "pelago",
    ],
  },
  {
    title: "Accounting System",
    brands: ["infor", "sunsystems", "sage", "oracle-netsuite", "quickbooks"],
  },
];

export const IntegrationSection = () => {
  return (
    <section className="container-section px-6 sm:px-9 xl:px-[160px] lg:px-[80px] flex flex-col">
      <h2
        data-aos="fade-up"
        className="hero-title md:text-[36px] lg:text-[48px] text-2xl color-white leading-[120%] font-bold text-center"
      >
        Available Integrations
      </h2>

      <Accordion type="multiple" className="w-full grid gap-10 mt-10 md:mt-16">
        {items.map((item, i) => (
          <AccordionItem
            data-aos="fade-up"
            key={i}
            value={`item-${i}`}
            className="border-0 accordion-trigger rounded-2xl p-4 grid gap-6 md:gap-10"
          >
            <AccordionTrigger className="text-white font-bold md:text-2xl text-left hover:no-underline p-2">
              {item.title}
            </AccordionTrigger>
            <AccordionContent className="p-2 flex flex-wrap gap-2 md:gap-10">
              {item.brands.map((brand) => (
                <img
                  className="bg-[#FAFAFA] w-[80px] h-[70px] md:w-[160px] md:h-[140px] object-contain p-3 rounded-sm md:rounded-lg"
                  key={brand}
                  src={`/icons/logo-brands/${brand}.webp`}
                  alt={brand}
                />
              ))}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};
