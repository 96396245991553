import CtaFormSection from "./Components/CtaForm";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import HeroSection from "./Components/Hero";
import AboutSection from "./Components/About";
import KeyFeaturesSection from "./Components/KeyFeatures";
import FeatureSection from "./Components/FeatureSection";
import { UseCaseSection } from "./Components/UseCaseSection";
import { IntegrationSection } from "./Components/Integration";
import { AppContextProvider } from "./context";

const App = () => {
  return (
    <AppContextProvider>
      <Header />
      <HeroSection />
      <AboutSection />
      <KeyFeaturesSection />
      <FeatureSection />
      <UseCaseSection />
      <IntegrationSection />
      <CtaFormSection />
      <Footer />
    </AppContextProvider>
  );
};

export default App;
