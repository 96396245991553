const featuresUp = [
  {
    src: "finance-features",
    aos: "fade-down",
  },
  {
    src: "commercial-features",
    aos: "fade-down",
  },
  {
    src: "operation-features",
    aos: "fade-down",
  },
];

const featuresDown = [
  {
    src: "pms-feature",
    aos: "fade-up",
  },
  {
    src: "ota-feature",
    aos: "fade-up",
  },
  {
    src: "bank-feature",
    aos: "fade-up",
  },
  {
    src: "internal-feature",
    aos: "fade-up",
  },
];

const FeatureSection = () => {
  return (
    <section className="container-section px-6 sm:px-9 xl:px-[160px] lg:px-[80px] flex-col md:flex-row">
      <h2
        data-aos="fade-up"
        className="hero-title z-20 relative xl:text-[48px] text-2xl md:text-[36px] color-white leading-[120%] font-bold text-center"
      >
        Unified Data Platform
        <br />
        Built for Hospitality
      </h2>

      <div className="xl:mt-16 xl:mb-20 mb-10 mt-11 z-10 relative grid grid-cols-12 gap-x-[6px] md:gap-x-3 lg:gap-x-6 gap-y-2 md:gap-y-3 lg:gap-y-4">
        {featuresUp.map((item, i) => (
          <div
            className="grid gap-y-1 md:gap-y-3 col-span-4"
            key={i}
            data-aos={item.aos}
            data-aos-delay={(i + 1) * 100}
          >
            <img
              className="w-full"
              src={`/images/unified-data-content/${item.src}.webp`}
              alt={item.src}
            />
            <img
              className="mx-auto w-1 md:w-2 lg:w-4"
              src="/icons/chevron-down.png"
              alt="chevron-down"
            />
          </div>
        ))}
        <img
          className="col-span-12 w-full"
          src="/images/unified-data-content/platform.webp"
          alt="platform.webp"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="300"
        />
        {featuresDown.map((item, i) => (
          <div
            className="grid gap-y-1 md:gap-y-3 col-span-3"
            key={i}
            data-aos={item.aos}
            data-aos-delay={(i + 1) * 100}
          >
            <img
              className="w-full"
              src={`/images/unified-data-content/${item.src}.webp`}
              alt={item.src}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeatureSection;
