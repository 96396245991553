const Footer = () => {
  return (
    <div className="footer flex lg:flex-row flex-col gap-y-4 justify-between align-middle items-center self-stretch px-10 py-6 lg:px-[120px] lg:py-[40px]  bottom-0">
      <img
        className="w-[160px] h-[40px]"
        src="/logo.webp"
        alt="logo-veridian"
      />
      <span className="text-sm footer-copyright">© 2023 Veridian</span>
    </div>
  );
};

export default Footer;
