import { ReactSVG } from "react-svg";

export const UseCaseSection = () => {
  return (
    <section className="container-section px-6 sm:px-9 xl:px-[160px] lg:px-[80px] flex flex-col">
      <h2
        data-aos="fade-up"
        className="hero-title md:text-[48px] text-2xl color-white leading-[120%] font-bold text-center"
      >
        AR Reconciliation
        <br />
        Use Case
      </h2>

      <div className="flex flex-col lg:flex-row grid-cols-1 md:grid-cols-2 gap-10 mt-10">
        <div data-aos="fade-up" className="grid relative flex-1">
          <div className="use-case-icon relative">
            <ReactSVG
              className="lg:w-10 lg:h-10 w-5 h-5"
              src={`/icons/icon-40px-recon-task.svg`}
            />
          </div>
          <div className="use-case-box">
            <h3 className="lg:text-xl text-center text-white">
              AR finance staff{" "}
              <b className="text-[#75C5C0]">spent 50-80% of their time</b> daily
              on <b className="text-[#75C5C0]">manual reconciliation tasks</b>
            </h3>
          </div>
        </div>

        <div data-aos="fade-up" className="grid relative flex-1">
          <div className="use-case-icon relative">
            <ReactSVG
              className="lg:w-10 lg:h-10 w-5 h-5"
              src={`/icons/icon-40px-error.svg`}
            />
          </div>
          <div className="use-case-box">
            <h3 className="lg:text-xl text-center text-white">
              <b className="text-[#75C5C0]">3 out of 5 hotels</b> struggle to
              close books on time due to{" "}
              <b className="text-[#75C5C0]">inaccuracies and human errors</b>
            </h3>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10 overflow-x-hidden lg:gap-16 mt-10 justify-center items-center lg:flex-row-reverse">
        <div data-aos="fade-left" className="flex-1">
          <h4 className="lg:text-xl text-white">
            Veridian helps hotels{" "}
            <b className="text-[#75C5C0]">
              automate mission-critical AR reconciliation
            </b>{" "}
            tasks that are essential for accurate daily P&L reporting by
            ingesting from various data sources such as PMS, OTA, payment
            processor, and bank statements, resulting in the reduction of{" "}
            <b className="text-[#75C5C0]">
              reconciliation time to less than 15mins.
            </b>
          </h4>
        </div>
        <div data-aos="fade-right" className="flex-1 w-full">
          <ReactSVG className="w-full" src={`/images/use-case-img.svg`} />
        </div>
      </div>
    </section>
  );
};
