import { useState } from "react";
import { ReactSVG } from "react-svg";
import { useAppContext } from "../../context";

const Header = () => {
  const [openNav, setopenNav] = useState(false);
  const { handleScrollToCTA } = useAppContext();
  return (
    <>
      <div className="header flex justify-between align-middle self-stretch px-6 pt-6 pb-4 lg:px-[120px] lg:py-[40px] sticky top-0">
        <a href="/">
          <img
            className="w-[159px] h-[40px]"
            src="/logo.webp"
            alt="logo-veridian"
          />
        </a>
        <div className="lg:flex gap-8 hidden">
          {/* <button className="bg-transparent py-[10px] px-[18px] color-white font-bold text-base hover:text-[#4C97D5]">
            Login
          </button> */}
          <button
            onClick={handleScrollToCTA}
            className="bg-transparent py-[10px] px-[18px] button-get-started color-white font-bold text-base"
          >
            Contact Us
          </button>
        </div>
        {/* <button
          className="block lg:hidden color-white z-30"
          onClick={() => setopenNav(true)}
        >
          <ReactSVG src="/icons/hamburger_icon.svg" />
        </button> */}
      </div>
      <div
        className={`${
          openNav
            ? "!opacity-100 block !translate-y-0"
            : "opacity-0  hidden !translate-x-0"
        } transition-all duration-1000 ease-in-out min-h-screen min-w-full overflow-hidden backdrop-blur-xl fixed top-0 left-0 right-0 z-20 `}
      >
        <div className="flex justify-end p-6">
          <button className="w-6 h-6" onClick={() => setopenNav(false)}>
            <ReactSVG src="/icons/close_icon.svg" />
          </button>
        </div>
        <div className="flex items-center justify-center h-[calc(100vh-72px)] px-6 pb-6">
          <button
            onClick={() => {
              handleScrollToCTA();
              setopenNav(false);
            }}
            className="text-xl color-white font-bold leading-[150%] py-8 border-y w-full text-center border-y-white border-opacity-10"
          >
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
